import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBarAlt from "./SideBarAlt";

import "./PrivacyPolicy.css";

function PrivacyPolicy(props) {

  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    //if (!user) return navigate("/login");

    async function fetchData(){
      const userDoc = await getUser(user);
      setUserInfo(userDoc);
      setVersionID(await fetchVersionInfo());
      console.log("Props: ", props);
    }

    fetchData();
    //console.log(window);

  }, [user, loading]);

  let title = "Privacy Policy";
  let activeNav = "privacy";

  return(
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
        <main id="content" role="main" className="bg-light">
          <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: ''}]}/>
          <SideBarAlt />

            <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2">
              <h1 className="pt-10">Privacy Policy</h1>
              
              <p>Effective date: 01 January, 2023</p>
              
              <p>This Privacy Policy describes how Change Real Estate, LLC (doing business as TransactionMinder) ("we," "us," or "our") collects, uses, and protects the personal information you provide on the TransactionMinder website (https://transactionminder.com). Please read this Privacy Policy carefully before using our services or submitting any personal information.</p>
              
              <h2>Information Collection and Use</h2>
              
              <p>We collect personal information from you through the registration form and contact form on our website. The types of personal information we collect may include your name, email address, and phone number. We use this information to facilitate account creation, authentication, and the delivery of services to you.</p>
              
              <h2>Information Sharing</h2>
              
              <p>We do not share your personal information with third parties.</p>
              
              <h2>Cookies</h2>
              
              <p>We use cookies on our website to store application-related information. By using our website, you consent to the use of these cookies.</p>
              
              <h2>User Consent</h2>
              
              <p>By creating an account on TransactionMinder, you provide your consent for us to collect, use, and process your personal information in accordance with this Privacy Policy.</p>
              
              <h2>Data Security</h2>
              
              <p>We take the security of your personal information seriously and implement a combination of organizational and technical measures to protect it.</p>
              
              <h2>Data Retention</h2>
              
              <p>We retain your personal information until you choose to delete your account.</p>
              
              <h2>Access, Update, and Deletion of Personal Information</h2>
              
              <p>You have the right to access, update, and delete your personal information. You can manage your personal information through your account settings on TransactionMinder.</p>
              
              <h2>Automated Decisions</h2>
              
              <p>We do not make any automated decisions based on user personal information.</p>
              
              <h2>Changes to this Privacy Policy</h2>
              
              <p>We may update this Privacy Policy from time to time. Any changes will be notified to you via email from privacy@transactionminder.com.</p>
              
              <h2>Compliance with Data Protection Laws</h2>
              
              <p>We comply with relevant data protection laws and regulations, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).</p>
              
              <h2>Contact Us</h2>
              
              <p>If you have any questions or concerns about this Privacy Policy, please contact us at privacy@transactionminder.com.</p>
      </div>
      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />

    </>

  )
}

export default PrivacyPolicy;