import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, doc, getDoc, query, getDocs, collection, where, setDoc } from "firebase/firestore";
import { GoogleAuthProvider, getAuth, signInWithCustomToken, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, deleteUser } from "firebase/auth";

//Source: https://blog.logrocket.com/user-authentication-firebase-react-apps/

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA91gKvUo1P71CT13GP4AUMPX_7VnGRGBA",
  authDomain: "tennis-team-manager.firebaseapp.com",
  projectId: "tennis-team-manager",
  storageBucket: "tennis-team-manager.appspot.com",
  messagingSenderId: "59813776944",
  appId: "1:59813776944:web:72805b6a239bf7f13967f5",
  measurementId: "G-EKGMVYGPG5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const sendMatchAvailabilityEmail = httpsCallable(functions, 'sendMatchAvailabilityEmail2024');
const sendPracticeAvailabilityEmail = httpsCallable(functions, 'sendPracticeAvailabilityEmail2024');
const createNewFirebaseUser = httpsCallable(functions, 'createUser');
const deleteFirebaseUser = httpsCallable(functions, 'deleteUser');

const googleProvider = new GoogleAuthProvider();
//googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
//googleProvider.addScope('https://www.googleapis.com/auth/calendar.readonly');

const getUser = async (user) => {
  try {
    const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    const userDoc = await getDocs(q);
    const data = userDoc.docs[0].data();
    data.authorized = true;
    console.log(data);
    return data;
  } catch (err) {
    console.log(err);
  }
};

const updateUser = async (user) => {
  try {
    return await setDoc(doc(db, "users", user.uid), user);
  } catch (err) {
    console.error(err);
  }
  return(false);
};


const fetchVersionInfo = async () => {

  const metaRef = doc(db, "appinfo", "versionInfo");
  const metaSnap = await getDoc(metaRef);
  const metaDoc = metaSnap.data();
  console.log(metaDoc);
  return(metaDoc.versionID);   

};

const registerWithEmailAndPassword = async (firstName, lastName, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      firstName,
      lastName,
      authProvider: "local",
      authorized: true,
      emailAddress: email,
    });
    return(user);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log(res);
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    console.log(docs);
    if (docs.docs.length === 0) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        authorized: true,
        defaultTemplate: "LVR2023",
        photoURL: user.photoURL,
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};


export {
  auth,
  db,
  sendMatchAvailabilityEmail,
  sendPracticeAvailabilityEmail,
  createNewFirebaseUser,
  deleteFirebaseUser,
  signInWithGoogle,
  signInWithCustomToken,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getUser,
  updateUser,
  fetchVersionInfo,
};
