import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "./firebaseAuth";
import "./Reset.css";
import logo from "./logo.800x200.png";

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading]);
  return (

    <div className="mt-10">
      <div className="container">
      <header className="text-center mb-5">
          <img width={400} src={logo} alt="Transaction Minder" />
        </header>

        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                  />
                </div>
                <div className="mb-3">
                  <button className="btn btn-dark" onClick={() => sendPasswordReset(email)} >Send password reset email</button>
                </div>
                <div>
                  Don't have an account? <Link to="/register">Register</Link> now.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Reset;