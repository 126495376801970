import React from "react";
import { logout } from "./firebaseAuth";
import "./SideBar.css";

function SideBar(props) {
  return (
    <div className="navbar-expand-lg navbar-light sticky-top">
      <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
        <div className="card flex-grow-1 mb-5">
          <div className="card-body">
            <div className="text-center mb-5">
              {props.userInfo &&
                <>

                  {(props.user && props.user.photoURL) &&
                  <div className="avatar avatar-xxl avatar-circle mb-3">
                    <img className="avatar-img" referrerPolicy="no-referrer" src={props.user.photoURL} alt="" />
                  </div>
                  }

                  <h4 className="card-title mb-0">{props.userInfo.firstName} {props.userInfo.lastName}</h4>
                  <div className="card-text small">
                    {props.user ? <div>{props.user.email}</div> : ''}
                    {props.userInfo ? <div>{props.userInfo.mobilePhone}</div> : ''}
                  </div>
                </>
              }
            </div>

            <span className="text-cap">{props.team ? props.team.teamTitle : 'Team'}</span>

            <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
              <li className="nav-item">
                <a className={ props.activeNav === 'matches' ? "nav-link active" : "nav-link"} href="/matches">
                <i className="bi bi-calendar2-check nav-icon"></i> Matches
                </a>
              </li>
              <li className="nav-item">
                <a className={ props.activeNav === 'practices' ? "nav-link active" : "nav-link"} href="/practices">
                <i className="bi bi-infinity nav-icon"></i> Practices
                </a>
              </li>
              <li className="nav-item">
                <a className={ props.activeNav === 'teamInfo' ? "nav-link active" : "nav-link"} href="/roster">
                <i className="bi bi-people-fill nav-icon"></i> Team Roster
                </a>
              </li>
              <li className="nav-item">
                <a className={ props.activeNav === 'docs' ? "nav-link active" : "nav-link"} href="/docs">
                <i className="bi bi-file-pdf-fill nav-icon"></i> Docs
                </a>
              </li>
            </ul>


            <span className="text-cap">Account</span>

            <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
            { false && 
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                  <i className="bi-sliders nav-icon"></i> Preferences
                </a>
              </li>
              }
              { false && 
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                  <i className="bi-person-badge nav-icon"></i> Personal Info
                </a>
              </li>
              }
              <li className="nav-item">
                <a className="nav-link"   href="/myTeams">
                  <i className="bi-people nav-icon"></i> My Teams
                </a>
              </li>
              { false && 
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                  <i className="bi-shield-shaded nav-icon"></i> Security
                </a>
              </li>
              }
              { false && 
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                  <i className="bi-bell nav-icon"></i> Notifications
                </a>
              </li>
              }
            </ul>

            {props.userInfo && props.userInfo.isAdmin && 
            <>
              <span className="text-cap">Admin</span>
              <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                <li className="nav-item">
                  <a className={ props.activeNav === 'facilities' ? "nav-link active" : "nav-link"} href="/facilities">
                  <i className="bi bi-geo-alt-fill nav-icon"></i> Facilities
                  </a>
                </li>
                <li className="nav-item">
                  <a className={ props.activeNav === 'seasons' ? "nav-link active" : "nav-link"} href="/seasons">
                  <i className="bi bi-calendar3 nav-icon"></i> Seasons
                  </a>
                </li>
                <li className="nav-item">
                  <a className={ props.activeNav === 'teams' ? "nav-link active" : "nav-link"} href="/teams">
                  <i className="bi-people nav-icon"></i> Teams
                  </a>
                </li>
                <li className="nav-item">
                  <a className={ props.activeNav === 'players' ? "nav-link active" : "nav-link"} href="/players">
                  <i className="bi-people nav-icon"></i> Players
                  </a>
                </li>
              </ul>
            </>
            }
            

            <div className="d-lg-none">
              <div className="dropdown-divider"></div>

              <ul className="nav nav-sm nav-tabs nav-vertical">
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={logout}>
                    <i className="bi-box-arrow-right nav-icon"></i> Log out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
);
}

export default SideBar;
