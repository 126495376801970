import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, getUser, logout } from "./firebaseAuth";
import "./SideBarAlt.css";

function SideBarAlt(props) {

  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');

  useEffect(() => {
    if (loading) return;

    async function fetchData(){
      const userDoc = await getUser(user);
      setUserInfo(userDoc);
    }

    fetchData();

  }, [user, loading]);

  return (
      <div>
        <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
          <div className="card flex-grow-1 mb-5">
            <div className="card-body">
              {userInfo 
              ?
                <div className="small text-center">
                  <button className="btn btn-dark btn-sm mt-2" onClick={logout}>Log out</button>
                </div>
              :
                <div className="small text-center">
                  <a className="btn btn-dark btn-sm mt-2" href="/login">Login</a>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
  );
}

export default SideBarAlt;
