import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo, sendMatchAvailabilityEmail } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
//import { isBefore } from "date-fns";
import { getTeam, addUpdateTeam, getTeams, getFacilities, getSeasons, addSeason } from "./firebaseData";
//import { Timestamp } from "firebase/firestore";
import "./Seasons.css";

function Seasons(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [teams, setTeams] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [seasonsByID, setSeasonsByID] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [facilitiesByID, setFacilitiesByID] = useState({});
  const [seasonDate, setSeasonDate] = useState(new Date());
  const [showSaveSeasonAlert, setShowSaveSeasonAlert] = useState(false);
  const [showSaveTeamAlert, setShowSaveTeamAlert] = useState(false);
  const addSeasonModalRef = useRef();
  const addTeamModalRef = useRef();

  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);

    setTeam(await getTeam(userDoc.displayTeam));
    setTeams(await getTeams());
    var savedSeasonsByID = {};
    var savedSeasons = await getSeasons()
    savedSeasons.forEach((aSeason) => {
      savedSeasonsByID[aSeason.id] = aSeason;
    });
    var savedFacilitiesByID = {};
    var savedFacilities = await getFacilities();
    savedFacilities.forEach((aFacility) => {
      savedFacilitiesByID[aFacility.id] = aFacility;
    });

    setSeasons(savedSeasons);
    setSeasonsByID(savedSeasonsByID);
    setFacilities(savedFacilities);
    setFacilitiesByID(savedFacilitiesByID);
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if(!user.uid === 'W3dBoKNktkR8yp2ByjoRR7okfVn2') return navigate("/");

    console.log('User UID: ', user.uid);

    fetchData();

  }, [user, loading]);

  //console.log('Rendering Matches...');
  let title = "Seasons";
  let activeNav = "seasons";
  let link = "/seasons";
  let emptyMessage = 'No seasons found...';

  const handleAddSeason = (event) => {
    console.log("Add Season...");

    const addSeasonModal = addSeasonModalRef.current;
    const bsModal = new bootstrap.Modal(addSeasonModal);
    bsModal.show();    

  }

  const handleSaveSeason = async (event) =>{
    console.log("Save Season...");
    const submittedData = new FormData(document.getElementById("addSeasonForm"));
    //console.log(submittedData);

    var season = { year: seasonDate.getFullYear() }
    for (const [key, value] of submittedData) {
      season[key] = value
      //console.log(key, ":", value);
    }    
    //console.log("Year: ", seasonDate.getFullYear());
    var seasonID = seasonDate.getFullYear().toString() + "-" + season.season + "-" + season.type + "-" + season.age + "-" + season.ntrp;
    var seasonTitle = seasonDate.getFullYear().toString() + " " + season.season + " " + season.type + " " + season.age + " " + season.ntrp;
    season.id = seasonID.toUpperCase();
    season.title = seasonTitle;
    season.lineCountDoubles = parseInt(season.lineCountDoubles);
    season.lineCountSingles = parseInt(season.lineCountSingles);
    console.log(season);

    setShowSaveSeasonAlert(true);
    await addSeason(season);
    fetchData();

    let timeout = setTimeout(() => {
      setShowSaveSeasonAlert(false);
      const addSeasonModal = addSeasonModalRef.current;
      const bsModal = bootstrap.Modal.getInstance(addSeasonModal);
      bsModal.hide();   
      }, 2000);

  }

  const handleAddTeam = async (season, event) => {
    console.log("Add team to season:", season);

    const addTeamModal = addTeamModalRef.current;
    const bsModal = new bootstrap.Modal(addTeamModal);
    document.getElementById("teamSeason").value = season.id;
    bsModal.show();    
  }

  const handleSaveTeam = async (event) => {
    console.log("Save Team...");
    var newTeamForm = {};
    const submittedData = new FormData(document.getElementById("addTeamForm"));
    //console.log(submittedData);

    for (const [key, value] of submittedData) {
      newTeamForm[key] = value
      //console.log(key, ":", value);
    }  
    console.log("Form Data:", newTeamForm);

    var teamSeason = seasonsByID[newTeamForm.teamSeason];
    var facility = facilitiesByID[newTeamForm.facility];

    var newTeam = {
      address: facility.address,
      map: facility.mapEmbed,
      season: teamSeason.id,
      seasonName: teamSeason.season,
      name: facility.name,
      id: newTeamForm.ustaTeamNumber,
      ustaLink: newTeamForm.ustaLink,
      year: teamSeason.year,
      lineCountDoubles: parseInt(teamSeason.lineCountDoubles),
      lineCountSingles: parseInt(teamSeason.lineCountSingles),
      teamTitle: teamSeason.title + " " + newTeamForm.teamName,
      teamName: newTeamForm.teamName,
      age: teamSeason.age,
      ntrp: teamSeason.ntrp,
      type: teamSeason.type,
    };

    console.log("New Team:", newTeam);  
    setShowSaveTeamAlert(true)
    await addUpdateTeam(newTeam);
    fetchData();

    let timeout = setTimeout(() => {
      setShowSaveTeamAlert(false);
      const addTeamModal = addTeamModalRef.current;
      const bsModal = bootstrap.Modal.getInstance(addTeamModal);
      bsModal.hide();   
      }, 2000);
    
  }

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>
                      <div>
                        <h3>Seasons</h3>
                      
                        <div className="my-5">
                        { seasons && 
                          <h5 className="clearfix">Seasons ({seasons.length}) 
                          <a className="ms-2 link-primary" name="addSeason" id="addSeason" onClick={handleAddSeason} title="Add Season" alt="Add Season"><i className="bi bi-plus-circle-fill"></i></a>
                          </h5>
                        }
                        <div className="row row-cols-md-1">
                        { seasons.length === 0 && 
                          <div className="text-center">
                              <h2>Loading...</h2>
                                <Rings
                                height="180"
                                width="180"
                                color="#0000ff"
                                radius="6"
                                wrapperStyle={{display:"block"}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="rings-loading"
                              />
                          </div>
                        }

                        {seasons && seasons.length > 0 && seasons.map(season => (
                          <div className="col" key={season.id}>
                          <div className="card my-2">
                            <div className="card-body">
                              <div className="card-title text-black clearfix">
                                <button type="button" className="btn btn-outline-primary float-end" onClick={handleAddTeam.bind(this, season)}><i className="bi bi-plus-circle-fill me-2"></i>Add Team</button>
                                <strong>Name</strong>: { season.id } <br />
                                <strong>Title</strong>: { season.title }<br />
                                <strong>Year</strong>: { season.year }

                                {season.season &&
                                <>
                                  <br /><strong>Season</strong>: { season.season }
                                </>
                                }

                                {season.type &&
                                <>
                                  <br /><strong>Type</strong>: { season.type }
                                </>
                                }

                                {season.age &&
                                <>
                                  <br /><strong>Age</strong>: { season.age }
                                </>
                                }

                                {season.ntrp &&
                                <>
                                  <br /><strong>NTRP</strong>: { season.ntrp }
                                </>
                                }

                                {season.lineCountSingles &&
                                <>
                                  <br /><strong>Singles Lines</strong>: { season.lineCountSingles }
                                </>
                                }
                                {season.lineCountDoubles &&
                                <>
                                  <br /><strong>Doubles Lines</strong>: { season.lineCountDoubles }
                                </>
                                }
                              </div>
                              <div className="card-text">
                                <div>
                                  <p className="fw-bold mt-5 mb-0">Teams</p>
                                  {teams && teams.length > 0 && teams.map(theTeam => (
                                    <div key={theTeam.id}>
                                    {theTeam.season === season.id ? <div>{theTeam.id} - {theTeam.teamName}</div> : <></>}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        ))}
                        </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </main>

      <div className="modal fade" id="addSeason" tabIndex="-1" aria-labelledby="addSeasonLabel" aria-hidden="true" ref={addSeasonModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addSeasonLabel">Add Season</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black"> 
              <form id="addSeasonForm">                 

                <div className="mb-3">
                  <label htmlFor="seasonDate"  className="form-label me-2 fw-bold">Year: </label><br />
                  <ReactDatePicker id="seasonDate" className="form-control" minDate={new Date()} showYearPicker dateFormat="yyyy" selected={seasonDate} onChange={(date) => setSeasonDate(date)} />
                </div>

                <div className="mb-3">
                  <label htmlFor="season"  className="form-label me-2 fw-bold">Season: </label>
                  <select className="form-select" aria-label="Season"  id="season" name="season">
                    <option value="Winter">Winter</option>
                    <option value="Spring">Spring</option>
                    <option value="Summer">Summer</option>
                    <option value="Fall">Fall</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="type"  className="form-label me-2 fw-bold">Type: </label>
                  <select className="form-select" aria-label="Type"  id="type" name="type">
                    <option value="Men's">Men's</option>
                    <option value="Women's">Women's</option>
                    <option value="Mixed">Mixed</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="age"  className="form-label me-2 fw-bold">Min Age: </label>
                  <select className="form-select" aria-label="Age Level"  id="age" name="age">
                    <option value="18+">18 and over</option>
                    <option value="40+">40 and over</option>
                    <option value="50+">50 and over</option>
                    <option value="55+">55 and over</option>
                    <option value="65+">65 and over</option>
                  </select>
                </div>


                <div className="mb-3">
                  <label htmlFor="ntrp"  className="form-label me-2 fw-bold">NTRP Level: </label>
                  <select defaultValue={"3.5"} className="form-select" aria-label="NTRP Level"  id="ntrp" name="ntrp">
                    <option value="2.0">2.0</option>
                    <option value="2.5">2.5</option>
                    <option value="3.0">3.0</option>
                    <option value="3.5">3.5</option>
                    <option value="4.0">4.0</option>
                    <option value="4.5">4.5</option>
                    <option value="5.0">5.0</option>
                    <option value="5.5">5.5</option>
                    <option value="6.0">6.0</option>
                    <option value="6.5">6.5</option>
                    <option value="7.0">7.0</option>
                    <option value="7.5">8.5</option>
                    <option value="8.0">8.0</option>
                    <option value="8.5">8.5</option>
                    <option value="9.0">9.0</option>
                    <option value="9.5">9.5</option>
                    <option value="10.0">10.0</option>
                  </select>
                </div>


                <div className="mb-3">
                  <label htmlFor="lineCountSingles" className="form-label me-2 fw-bold">Singles Line Count: </label>
                  <select defaultValue={"1"} className="form-select" aria-label="Singles Line Count" id="lineCountSingles" name="lineCountSingles">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="lineCountDoubles"  className="form-label me-2 fw-bold">Doubles Line Count: </label>
                  <select defaultValue={"3"} className="form-select" aria-label="Doubles Line Count" id="lineCountDoubles" name="lineCountDoubles">
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveSeason}>Save Season</button>
            </div>
          </div>
        </div>
      
        { showSaveSeasonAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="saveSeasonToast" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <h4>Updating... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
                </div>
            </div>
          </div>
        )}

      </div>


      <div className="modal fade" id="addTeam" tabIndex="-1" aria-labelledby="addTeamLabel" aria-hidden="true" ref={addTeamModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addTeamLabel">Add Team</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black"> 
              <form id="addTeamForm">                 
                <div className="mb-3">
                  <label htmlFor="teamSeason" className="form-label fw-bold">Season</label>
                  <input type="text" className="form-control" readOnly={true} name="teamSeason" id="teamSeason" />
                </div>

                <div className="mb-3">
                  <label htmlFor="facility"  className="form-label fw-bold">Facility: </label>
                  <select className="form-select" aria-label="Facility"  id="facility" name="facility">
                    {facilities && facilities.length > 0 && facilities.map(facility => (
                      <option key={facility.id} value={facility.id}>{facility.name}</option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="ustaTeamNumber" className="form-label fw-bold">USTA Team Number</label>
                  <input type="text" className="form-control" name="ustaTeamNumber" id="ustaTeamNumber" />
                </div>

                <div className="mb-3">
                  <label htmlFor="teamName" className="form-label fw-bold">Team Name</label>
                  <input type="text" className="form-control" name="teamName" id="teamName"/>
                </div>

                <div className="mb-3">
                  <label htmlFor="ustaLink" className="form-label fw-bold">Team USTA Website Link</label>
                  <input type="text" className="form-control" name="ustaLink" id="ustaLink" />
                </div>

              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveTeam}>Save Team</button>
            </div>
          </div>
        </div>
      
        { showSaveTeamAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="saveTeamToast" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <h4>Updating... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
                </div>
            </div>
          </div>
        )}

      </div>


      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Seasons;