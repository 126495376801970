import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo, createNewFirebaseUser } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";
import { CSVLink } from "react-csv";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import { getTeam, getPlayers, updateUser, deleteUser } from "./firebaseData";
import "./Players.css";

function Players(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState([]);
  const [players, setPlayers] = useState([]);
  const [playersById, setPlayersById] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [playerSearch, setPlayerSearch] = useState('');
  const [ntrpFilter, setNTRPFilter] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  
  const [emailAddress, setEmailAddress] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [ntrp, setNtrp] = useState("3.5");
  const [gender, setGender] = useState("M");
  const [playerID, setPlayerID] = useState("");

  const [showSaveNewPlayerAlert, setShowSaveNewPlayerAlert] = useState(false);
  const addNewPlayerModalRef = useRef();
  
  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);

    if(userDoc.displayTeam){
      console.log('Getting team...');
      setTeam(await getTeam(userDoc.displayTeam));
    }
    let thePlayers = await getPlayers();

    let playerListByID = {};
    thePlayers.forEach((thePlayer) => {
      playerListByID[thePlayer.id] = thePlayer;
    });
    setPlayersById(playerListByID);
    setPlayers(thePlayers);
    setFilteredPlayers(thePlayers);
    setPlayerSearch('');
    setNTRPFilter('');
    setGenderFilter('');
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    if(!user.uid === 'W3dBoKNktkR8yp2ByjoRR7okfVn2') return navigate("/");
    console.log('User UID: ', user.uid);
    fetchData();
    console.log(window);

  }, [user, loading]);

  const filterPlayers = (args) => {

    console.log("Filter Players: ", args);
    let playerResults = players;

    if (args.playerName && args.playerName.length > 0) {
      let searchExp = new RegExp(args.playerName, "i");
      playerResults = playerResults.filter((player) => {
        return player.lastName.match(searchExp) || player.firstName.match(searchExp);
      });    
    }
    //console.log("Player Results after Name Filter: ", playerResults);

    if (args.ntrp && args.ntrp.length > 0) {
      playerResults = playerResults.filter((player) => {
        return player.ntrp.match(args.ntrp);
      });
    } 

    if (args.gender && args.gender.length > 0) {
      playerResults = playerResults.filter((player) => {
        return player.gender.match(args.gender);
      });    
    }   
    
    setFilteredPlayers(playerResults);
  }

  const getCSVList = (() => {
    let results = [['firstName', 'lastName', 'emailAddress', 'mobilePhone', 'ntrp', 'gender']];
    filteredPlayers.forEach((player) => {
      results.push([player.firstName, player.lastName, player.emailAddress, player.mobilePhone, player.ntrp, player.gender]);
    });
    return(results);
  })

  const handlePlayerSearchChange = ((event) => {
    let searchText = event.target.value; 
    setPlayerSearch(searchText)
    filterPlayers({playerName: searchText, ntrp: ntrpFilter, gender: genderFilter});
  });

  const handleNTRPFilterChange = ((event) => {
    let selectedNTRPFilter = event.target.value
    setNTRPFilter(selectedNTRPFilter);
    filterPlayers({playerName: playerSearch, ntrp: selectedNTRPFilter, gender: genderFilter});
  });

  const handleGenderFilterChange = ((event) => {
    let selectedGenderFilter = event.target.value
    setGenderFilter(selectedGenderFilter);
    filterPlayers({playerName: playerSearch, ntrp: ntrpFilter, gender: selectedGenderFilter});
  });

  const handleAddNewPlayer = () => {
    console.log("Add New Player!");

    const addNewPlayerModal = addNewPlayerModalRef.current;
    const bsModal = new bootstrap.Modal(addNewPlayerModal);
    bsModal.show();    

  }

  const handleCreateNewPlayer = async() => {

    console.log("Create New Player... Firebase Auth and then Firestore User");

    setShowSaveNewPlayerAlert(true);


    if(!playerID){
      //Create user in Firebase Auth (using callable function to prevent login...)
      let result = await createNewFirebaseUser({emailAddress: emailAddress});

      //Then Create User in Firestore...
      console.log('Result: ', result);
      console.log('Result Data: ', result.data);
      console.log('New User ID: ', result.data.uid);

      let newUserObject = {
        uid: result.data.uid,
        id: result.data.uid,
        emailAddress: emailAddress,
        firstName: firstName,
        lastName: lastName,
        mobilePhone: mobilePhone,
        ntrp: ntrp,
        gender: gender,
        registeredTeams: [],
        teams: [],
        displayTeam: ''
      };
  
      await updateUser(newUserObject);

    } else {

      //Only update the existing player...
      var editedPlayer = playersById[playerID];
      editedPlayer.firstName = firstName;
      editedPlayer.lastName = lastName;
      editedPlayer.emailAddress = emailAddress;
      editedPlayer.mobilePhone = mobilePhone;
      editedPlayer.ntrp = ntrp;
      editedPlayer.gender = gender;

      await updateUser(editedPlayer);

    }
    
    fetchData();

    let timeout = setTimeout(() => {
      setShowSaveNewPlayerAlert(false);
      const addNewPlayerModal = addNewPlayerModalRef.current;
      const bsModal = bootstrap.Modal.getInstance(addNewPlayerModal);
      bsModal.hide();   
    }, 2000);
        
  }

  const handleEditPlayer = async(player) => {
    console.log("Edit Player:", player);

    setPlayerID(player.id);
    setFirstName(player.firstName);
    setLastName(player.lastName);
    setEmailAddress(player.emailAddress);
    setMobilePhone(player.mobilePhone);
    setNtrp(player.ntrp);
    setGender(player.gender);

    const addNewPlayerModal = addNewPlayerModalRef.current;
    const bsModal = new bootstrap.Modal(addNewPlayerModal);
    bsModal.show();    

  }

  const handleDeletePlayer = async(player) => {
    console.log("Delete Player:", player);
    if (confirm("Are you sure you want to delete the player " + player.firstName + " " + player.lastName +"?")){
      console.log('Deleting...');
      await deleteUser(player)

      fetchData();
    }
  }

  //console.log('Rendering Dashboard...');
  let title = "Players";
  let activeNav = "players";
  let link = "/players";

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>
                    { players.length === 0 && 
                      <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    }
                    { players.length > 0 &&                     
                      <div>
                        <h3>Players</h3>
                        <div className="my-5 clearfix">
                          <h5 className="clearfix">Registered Players (showing {filteredPlayers.length} of {players.length} players)
                          <a className="link-primary ms-2" onClick={handleAddNewPlayer} title="Add New Player" alt="Add New Player"><i className="bi bi-plus-circle-fill"></i></a>
                          <CSVLink className="float-end small pe-auto" data={getCSVList()} download={"tennis-team-manager-players.csv"}>Download Results<i className="bi bi-box-arrow-down ms-2"></i></CSVLink>
                          </h5>
                          <div className="form-floating mb-1">
                            <input type="text" name="playerSearch" id="playerSearch" className="form-control" value={playerSearch} onChange={handlePlayerSearchChange} />
                            <label htmlFor="playerSearch"  className="form-label me-2">Search by first or last name... </label>
                          </div>

                          <div className="form-floating mb-1">
                            <select value={ntrpFilter} className="form-select" aria-label="NTRP Level"  id="ntrpFilter" name="ntrpFilter" onChange={handleNTRPFilterChange}>
                              <option value="">-</option>
                              <option value="2.0">2.0</option>
                              <option value="2.5">2.5</option>
                              <option value="3.0">3.0</option>
                              <option value="3.5">3.5</option>
                              <option value="4.0">4.0</option>
                              <option value="4.5">4.5</option>
                              <option value="5.0">5.0</option>
                              <option value="5.5">5.5</option>
                              <option value="6.0">6.0</option>
                              <option value="6.5">6.5</option>
                              <option value="7.0">7.0</option>
                              <option value="7.5">8.5</option>
                              <option value="8.0">8.0</option>
                              <option value="8.5">8.5</option>
                              <option value="9.0">9.0</option>
                              <option value="9.5">9.5</option>
                              <option value="10.0">10.0</option>
                            </select>
                            <label htmlFor="ntrp"  className="form-label me-2">NTRP Level: </label>
                          </div>

                          <div className="form-floating mb-1">
                            <select value={genderFilter} className="form-select" aria-label="Gender"  id="genderFilter" name="genderFilter" onChange={handleGenderFilterChange}>
                              <option value="">-</option>
                              <option value="M">Male</option>
                              <option value="F">Female</option>
                            </select>
                            <label htmlFor="genderFilter"  className="form-label me-2 fw-bold">Gender: </label>
                          </div>




                        <div className="row row-cols-md-2 text-black">
                        { filteredPlayers.length > 0 && filteredPlayers.map(teamMember => (
                          <div className="col" key={teamMember.id}>
                          <div className="card my-2">
                            <div className="card-body">
                              <div className="card-title clearfix">
                              {teamMember.firstName} {teamMember.lastName}
                              <a onClick={handleDeletePlayer.bind(this, teamMember)} title="Delete Player" alt="Delete Player" className="link-secondary float-end mx-2"><i className="bi bi-trash-fill"></i></a>
                              <a onClick={handleEditPlayer.bind(this, teamMember)} title="Edit Player" alt="Edit Player" className="link-primary float-end"><i className="bi bi-pencil-square"></i></a>
                              </div>

                              <div className="card-text">
                                <div className="small">
                                  <a href={"tel:" + teamMember.mobilePhone}>{teamMember.mobilePhone}</a>
                                </div>
                                <div className="small">
                                  <a href={"mailto:" + teamMember.emailAddress}>{teamMember.emailAddress}</a>
                                </div>
                                <div className="small">
                                  NTRP: {teamMember.ntrp}
                                </div>
                                {teamMember.gender &&
                                <div className="small">
                                  Gender: {teamMember.gender === "M" ? "Male" : "Female"}
                                </div>
                                }
                                { userInfo.uid === 'W3dBoKNktkR8yp2ByjoRR7okfVn2' &&                                
                                <div className="xsmall">
                                  <div>UID: {teamMember.id}</div>
                                  <div>Display Team: ID: {teamMember.displayTeam}</div>
                                </div>
                                }
                              </div>
                            </div>
                          </div>
                          </div>
                        ))}
                        </div>
                        </div>
                      </div>
                    }
                     </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
      </main>

      <div className="modal fade" id="addNewPlayer" tabIndex="-1" aria-labelledby="addNewPlayerLabel" aria-hidden="true" ref={addNewPlayerModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addNewPlayerLabel">{playerID ? "Edit Player" : "Add New Player"}</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black">                  
                <div className="mb-3">
                  <label htmlFor="firstName"  className="form-label me-2 fw-bold">First Name: </label>
                  <input type="text" className="form-control" value={firstName} id="firstName" name="firstName" onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" />
                </div>
                <div className="mb-3">
                  <label htmlFor="lastName"  className="form-label me-2 fw-bold">Last Name: </label>
                  <input type="text" className="form-control" value={lastName} id="lastName" name="lastName" onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" />
                </div>
                <div className="mb-3">
                  <label htmlFor="emailAddress"  className="form-label me-2 fw-bold">Email Address: </label>
                  <input type="email" className="form-control" value={emailAddress} id="emailAddress" name="emailAddress" onChange={(e) => setEmailAddress(e.target.value)} placeholder="E-mail Address" />
                </div>
                <div className="mb-3">
                  <label htmlFor="mobilePhone"  className="form-label me-2 fw-bold">Mobile Phone: </label>
                  <input type="tel" className="form-control" value={mobilePhone} id="mobilePhone" name="mobilePhone" onChange={(e) => setMobilePhone(e.target.value)} placeholder="Mobile Phone" />
                </div>

                <div className="mb-3">
                  <label htmlFor="ntrp"  className="form-label me-2 fw-bold">NTRP Level: </label>
                  <select value={ntrp} className="form-select" aria-label="NTRP Level"  id="ntrp" name="ntrp" onChange={(e) => setNtrp(e.target.value)}>
                    <option value="2.0">2.0</option>
                    <option value="2.5">2.5</option>
                    <option value="3.0">3.0</option>
                    <option value="3.5">3.5</option>
                    <option value="4.0">4.0</option>
                    <option value="4.5">4.5</option>
                    <option value="5.0">5.0</option>
                    <option value="5.5">5.5</option>
                    <option value="6.0">6.0</option>
                    <option value="6.5">6.5</option>
                    <option value="7.0">7.0</option>
                    <option value="7.5">8.5</option>
                    <option value="8.0">8.0</option>
                    <option value="8.5">8.5</option>
                    <option value="9.0">9.0</option>
                    <option value="9.5">9.5</option>
                    <option value="10.0">10.0</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="gender"  className="form-label me-2 fw-bold">Gender: </label>
                  <select value={gender} className="form-select" aria-label="Gender"  id="gender" name="gender" onChange={(e) => setGender(e.target.value)}>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleCreateNewPlayer}>{playerID ? "Save Edits" : "Add New Player"}</button>
            </div>
          </div>
        </div>
      
        { showSaveNewPlayerAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="savePracticeToast" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <h4>Adding... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
                </div>
            </div>
          </div>
        )}

      </div>

      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Players;