import { addHours, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { db, deleteFirebaseUser } from "./firebaseAuth";
import { doc, deleteDoc, getDoc, query, getDocs, collection, where, addDoc, setDoc, Timestamp, serverTimestamp, orderBy, updateDoc, or } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { createNumberMask, createAutoCorrectedDatePipe } from "text-mask-addons";

const validateEmail = (emailAddress) =>  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress))
    {
      return (true)
    }
      return (false)
  }

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const timeStringForTimeZone = (date, timeZone) => {
  return date.toLocaleString('en-US', { timeZone: timeZone, hour: 'numeric', minute: 'numeric', hour12: true })
}

const dateStringForTimeZone = (date, timeZone) => {
  return date.toLocaleString('en-US', { timeZone: timeZone, year: 'numeric', month: '2-digit', day: '2-digit' })
}

const dayOfWeekForTimeZone = (date, timeZone) => {
  return date.toLocaleString('en-US', { timeZone: timeZone, weekday: 'long' })
}

const dateString = (date) =>{
  //return(((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
  return(format(date, 'MM/dd/yyyy'));
}

const titleCase = (str) => {
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

const currencyMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const currencyMask = createNumberMask(currencyMaskOptions);
const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
const dateMaskPipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const getUserByID = async (uid) => {
  var userRef = doc(db, 'users', uid);
  var userSnap = await getDoc(userRef);
  var user = userSnap.data();  
  return(user);
}


const updateUser = async (user) => {
  console.log("Updating User: ", user);
  const userRef = doc(db, "users", user.uid);
  return await setDoc(userRef, user);
}

const deleteUser = async (user) => {
  console.log("Deleting User: ", user);
  await deleteDoc(doc(db, "users", user.id)); 
  deleteFirebaseUser({uid: user.id});
  return(true);
}


const sortRemindersByDate = (reminderA, reminderB) => {
  return(reminderA.dueDate - reminderB.dueDate);
}

const sortRemindersByOrder = (reminderA, reminderB) => {
  return(reminderA.order - reminderB.order);
}

const getSeasons = async () => {

  const q = query(collection(db, "seasons"), where("active", "==", true), orderBy("id", "desc"));
  const querySnap = await getDocs(q);

  let results = []
  for(const season of querySnap.docs) {
    const docID = season.id;
    const seasonData = season.data();
    seasonData.id = docID;
    results.push(seasonData);
  }
  console.log('Seasons: ', results)
  return(results);

}

const addSeason = async (season) => {
  console.log("Adding Season: ", season);
  const seasonRef = doc(db, "seasons", season.id);
  return await setDoc(seasonRef, season);
}

const getFacilities = async () => {

  const q = query(collection(db, "facilities"), orderBy("name"));
  const querySnap = await getDocs(q);

  let results = []
  for(const facility of querySnap.docs) {
    const docID = facility.id;
    const facilityData = facility.data();
    facilityData.id = docID;
    results.push(facilityData);
  }
  console.log('Facilities: ', results)
  return(results);

}

const getTeams = async () => {
  const seasons = await getSeasons();
  var seasonIDs = [];
  for(const season of seasons){
    seasonIDs.push(season.id);
  }

  const q = query(collection(db, "teams"), orderBy("id", "desc"));
  const querySnap = await getDocs(q);

  let results = []
  for(const team of querySnap.docs) {
    const docID = team.id;
    const teamData = team.data();
    teamData.id = docID;
    if(seasonIDs.includes(teamData.season)){
      results.push(teamData);  
    }
  }
  console.log('Teams: ', results)
  return(results);
}

const getTeamsForSeason = async (seasonID) => {

  const q = query(collection(db, "teams"), where("season", "==", seasonID), orderBy("id"));
  const querySnap = await getDocs(q);

  let results = []
  for(const team of querySnap.docs) {
    const docID = team.id;
    const teamData = team.data();
    teamData.id = docID;
    results.push(teamData);
  }
  console.log('Teams: ', results)
  return(results);
}

const getTeamsForIDList = async (teamIDList) => {
  const seasons = await getSeasons();
  var seasonIDs = [];
  for(const season of seasons){
    seasonIDs.push(season.id);
  }

  const q = query(collection(db, "teams"), where("id", "in", teamIDList), orderBy("id"));
  const querySnap = await getDocs(q);

  let results = []
  for(const team of querySnap.docs) {
    const docID = team.id;
    const teamData = team.data();
    teamData.id = docID;
    if(seasonIDs.includes(teamData.season)){
      results.push(teamData);  
    }
  }
  console.log('Teams for ID List: ', results, teamIDList)
  return(results);
}

const getTeam = async (teamID) => {
  const teamRef = doc(db, "teams", teamID);
  const teamSnap = await getDoc(teamRef);  
  const teamData = teamSnap.data();
  teamData.id = teamSnap.id;
  console.log("Team: ", teamData);
  return(teamData);
}

const addUpdateTeam = async (team) => {
  console.log("Add/Updating Team: ", team);
  const teamRef = doc(db, "teams", team.id);
  return await setDoc(teamRef, team);
}

const deleteTeam = async (teamID) => {
  console.log("Deleting Team: ", teamID);
  return await deleteDoc(doc(db, "teams", teamID));  
}

const getTeamMembers = async (teamID) => {
  const q = query(collection(db, "users"), where("registeredTeams", "array-contains", teamID), orderBy("lastName"), orderBy("firstName"));
  const querySnap = await getDocs(q);

  let results = {captains: [], players: []};
  for(const user of querySnap.docs) {
    const docID = user.id;
    const userData = user.data();
    userData.id = docID;
    results.players.push(userData);
    if(userData.captainsTeam && userData.captainsTeam.includes(teamID)){
      results.captains.push(userData);
    }
  }
  console.log('Users: ', results)
  return(results);
}

const getPlayers = async () => {

  const q = query(collection(db, "users"), orderBy("lastName"), orderBy("firstName"));
  //const q = query(collection(db, "users"));
  const querySnap = await getDocs(q);

  let results = []
  for(const user of querySnap.docs) {
    const docID = user.id;
    const userData = user.data();
    userData.id = docID;
    results.push(userData);
  }
  console.log('Users ordered by last and first name...: ', results)
  return(results);
}

const getPractices = async (team, teamMembers) => {
  const q = query(collection(db, "practices"), where("teamID", "==", team.id), orderBy("time", "desc"));
  const querySnap = await getDocs(q);

  let teamMembersByID = {};
  teamMembers.players.forEach((member) =>{
    teamMembersByID[member.uid] = member;
  });

  let results = []
  for(const practice of querySnap.docs) {
    const docID = practice.id;
    const practiceData = practice.data();
    practiceData.id = docID;
    practiceData.isOld = false;
    if (practiceData.time.toDate() < new Date()){
      practiceData.isOld = true;
    }
    var availablePlayers = [];
    var unavailablePlayers = [];
    practiceData.available.forEach((playerID) => {
      availablePlayers.push(teamMembersByID[playerID]);
    });
    practiceData.unavailable.forEach((playerID) => {
      unavailablePlayers.push(teamMembersByID[playerID]);
    });
    practiceData.availablePlayers = availablePlayers;
    practiceData.unavailablePlayers = unavailablePlayers;

    var noReplyPlayers = [];
    teamMembers.players.forEach((member) =>{
      var noReply = true;
      if(practiceData.available.includes(member.id)){
        noReply = false;
      }
      if(practiceData.unavailable.includes(member.id)){
        noReply = false;
      }
      if(noReply){
        noReplyPlayers.push(member);
      }
    });
    practiceData.noReplyPlayers = noReplyPlayers;
    practiceData.playersNeeded = practiceData.minPlayers - practiceData.available.length;
    if (practiceData.playersNeeded < 0) {
      practiceData.playersNeeded = 0;
    }
    results.push(practiceData);
  }
  console.log('Practices: ', results)
  return(results);
}

const addPractice = async (practice) => {
  console.log("Adding Practice: ", practice);
  return await addDoc(collection(db, "practices"), practice);
}

const deletePractice = async (practiceID) => {

  console.log("Deleting Practice: ", practiceID);
  return await deleteDoc(doc(db, "practices", practiceID));  

}

const setPracticeAvailability = async(practice, user, isAvailable) => {

  console.log("Before: ", practice);
  if(isAvailable){
    if(!practice.available.includes(user.uid)){
      practice.available.push(user.uid)
    }

    const index = practice.unavailable.indexOf(user.uid);
    if (index > -1) { // only splice array when item is found
      practice.unavailable.splice(index, 1); // 2nd parameter means remove one item only
    }
  } else {
    if(!practice.unavailable.includes(user.uid)){
      practice.unavailable.push(user.uid)
    }

    const index = practice.available.indexOf(user.uid);
    if (index > -1) { // only splice array when item is found
      practice.available.splice(index, 1); // 2nd parameter means remove one item only
    }

  }
  console.log("After: ", practice);

  const practiceRef = doc(db, "practices", practice.id);
  return updateDoc(practiceRef, practice);
}

const getMatches = async (teamID) => {
  const q = query(collection(db, "matches"), or(where("home", "==", teamID),where("visitor", "==", teamID)), orderBy("date"));
  const querySnap = await getDocs(q);

  let results = []
  for(const match of querySnap.docs) {
    const docID = match.id;
    const matchData = match.data();
    matchData.id = docID;
    results.push(matchData);
  }
  console.log('Matches: ', results)
  return(results);
}

const getMatch = async (matchID) => {
  const matchRef = doc(db, "matches", matchID);
  const matchSnap = await getDoc(matchRef);  
  const matchData = matchSnap.data();
  matchData.id = matchSnap.id;
  matchData.title = "Match ID: " + matchData.id + " Match Date: " + dateStringForTimeZone(matchData.date.toDate(), 'America/Los_Angeles');
  console.log("Match: ", matchData);
  return(matchData);  
}

const updateMatch = async (match) => {
  console.log("Updating Match: ", match);
  const matchRef = doc(db, "matches", match.id);
  return await setDoc(matchRef, match);
}

const setWonLoss = async (match, won) => {
  match.won = won;
  console.log("Updating Match WinLoss: ", match, won);
  const matchRef = doc(db, "matches", match.id);
  return await setDoc(matchRef, match);
}

const setMatchAvailability = async(match, user, isAvailable) => {

  console.log("Before: ", match);
  if(isAvailable){
    if(!match.available.includes(user.uid)){
      match.available.push(user.uid)
    }

    const index = match.unavailable.indexOf(user.uid);
    if (index > -1) { // only splice array when item is found
      match.unavailable.splice(index, 1); // 2nd parameter means remove one item only
    }
  } else {
    if(!match.unavailable.includes(user.uid)){
      match.unavailable.push(user.uid)
    }

    const index = match.available.indexOf(user.uid);
    if (index > -1) { // only splice array when item is found
      match.available.splice(index, 1); // 2nd parameter means remove one item only
    }

  }
  console.log("After: ", match);

  const matchRef = doc(db, "matches", match.id);
  return updateDoc(matchRef, match);
}

const getFileList = async(teamID) => {
  if(!teamID){
    return([]);
  }
  let fileList = []; //that.state.files;
  try{
  
    // Create a storage reference from our storage service
    var storage = getStorage();

    // Create a reference under which you want to list
    var listRef = ref(storage, (teamID + '/files'));  
    const res = await listAll(listRef)
    for(var i = 0; i < res.items.length; i++) {
      // All the items under listRef
      console.log(res.items[i]);
      var url = await getDownloadURL(res.items[i]);
      var name = res.items[i].name
      fileList.push({name: name, url: url});
    }
  } catch(err){
    console.log("Error...");
  }
  console.log(fileList);
  return(fileList);
}


export {
  currencyFormatter,
  currencyMask,
  dateString,
  dateStringForTimeZone,
  timeStringForTimeZone,
  dayOfWeekForTimeZone,
  dateMask,
  dateMaskPipe,
  phoneMask,
  validateEmail,
  titleCase,
  getUserByID,
  updateUser,
  deleteUser,
  getSeasons,
  addSeason,
  getFacilities,
  getTeams,
  getTeamsForIDList,
  getTeamsForSeason,
  getTeam,
  addUpdateTeam,
  deleteTeam,
  getTeamMembers,
  getPlayers,
  getPractices,
  setPracticeAvailability,
  addPractice,
  deletePractice,
  getMatches,
  getMatch,
  updateMatch,
  setWonLoss,
  setMatchAvailability,
  getFileList
};
