import logo from "./logo.800x200.inverted.png";
import "./PageFooter.css";

function PageFooter(props) {

  const thisYear = new Date().getFullYear();


  const uid = 'some-uid';

  return (
    <footer className="bg-dark">
      <div className="container pb-1 pb-lg-5">
        <div className="row content-space-t-2">
          
          <div className="col-lg-4 mb-7 mb-lg-0 me-10">
            <div className="mb-5">
              <a className="navbar-brand" href="/" aria-label="Space">
                <img className="navbar-brand-logo" src={logo} alt="" />
              </a>
            </div>

            <ul className="list-unstyled list-py-1">
              <li><a className="link-sm link-light" href="#"><i className="bi-geo-alt-fill me-1"></i> 3651 Lindell Rd, Suite D654, Las Vegas, NV 89107</a></li>
              <li><a className="link-sm link-light" href="tel:1-702-848-2971"><i className="bi-telephone-inbound-fill me-1"></i> +1 (702) 848-2971</a></li>
            </ul>
          
          </div>

          <div className="col-sm mb-7 mb-sm-0">
            <h5 className="text-white mb-3">Company</h5>

            <ul className="list-unstyled list-py-1 mb-0">
              <li><a className="link-sm link-light" href="#">About</a></li>
            </ul>

            <div className="col-sm my-7 mb-sm-0">
              <h5 className="text-white mb-3">Features</h5>

              <ul className="list-unstyled list-py-1 mb-0">
              <li><a className="link-sm link-light" href="#">Pricing</a></li>
              <li><a className="link-sm link-light" href="#">Release Notes</a></li>
              </ul>
            </div>

          </div>

          <div className="col-sm mb-7 mb-sm-0">
            <h5 className="text-white mb-3">Documentation</h5>

            <ul className="list-unstyled list-py-1 mb-0">
              <li><a className="link-sm link-light" href="#">Support</a></li>
              <li><a className="link-sm link-light" href="#">Docs</a></li>
            </ul>

            <div className="col-sm mt-7">
              <h5 className="text-white mb-3">Resources</h5>

              <ul className="list-unstyled list-py-1 mb-5">
                <li><a className="link-sm link-light" href="#"><i className="bi-question-circle-fill me-1"></i> Help</a></li>
                <li><a className="link-sm link-light" href="#"><i className="bi-person-circle me-1"></i> Your Account</a></li>
              </ul>
            </div>
          </div>

          <div className="border-top border-white-10 mb-7"></div>

          <div className="row my-7">
            <div className="col-sm mb-3 mb-sm-0">
              <ul className="list-inline list-separator list-separator-light mb-0">
                <li className="list-inline-item">
                  <a className="link-sm link-light" href="/privacy">Privacy Policy</a>
                </li>
                <li className="list-inline-item">
                  <a className="link-sm link-light" href="/terms">Terms</a>
                </li>
              </ul>
            </div>

            <div className="col-sm-auto">
            </div>
          </div>

          <div className="w-md-85 text-lg-center mx-lg-auto">
            <p className="text-white-50 small">Tennis Systems{  props.versionID ? ' v.' + props.versionID: '' }. &copy; {thisYear} 555 Labs. All rights reserved.</p>
            <p className="text-white-50 small">When you visit or interact with our sites, services or tools, we or our authorized service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.</p>
          </div>

        </div>
      </div>
  </footer>
  );
}

export default PageFooter;
