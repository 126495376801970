import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import { getTeam, getTeamsForIDList, updateUser} from "./firebaseData";
import "./MyTeams.css";

function MyTeams(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [teams, setTeams] = useState([]);

  const navigate = useNavigate();

  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);

    if(userDoc.displayTeam){
      console.log('Getting team...');
      setTeam(await getTeam(userDoc.displayTeam));
    }
    setTeams(await getTeamsForIDList(userDoc.registeredTeams));
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    console.log('User UID: ', user.uid);

    fetchData();
    console.log(window);

  }, [user, loading]);

  const handleChangeDisplayTeam = (async(teamID, event) =>{
    console.log("Change User's Display Team ID to: ", teamID);
    userInfo.displayTeam = teamID;
    await updateUser(userInfo);
    fetchData();
  });

  //console.log('Rendering Dashboard...');
  let title = "My Teams";
  let activeNav = "myTeams";
  let link = "/myTeams";

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>
                    { teams.length === 0 && 
                      <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    }
                    { teams.length > 0 && 
                      <div>
                        <h3>My Teams</h3>
                        <div className="my-5">
                          <h5 className="clearfix">My Teams <span className="fw-normal text-muted small">(current display team highlighted)</span></h5>
                          <div className="row row-cols-1 row-cols-sm-1 row-cols-xs-1 row-cols-md-1 row-cols-lg-2 text-black">
                          { teams.length > 0 && teams.map(theTeam => (
                            <div className="col" key={theTeam.id}>
                            <div className={ theTeam.id === userInfo.displayTeam ?  "hover-pointer card my-2 border border-primary border-5" : "hover-pointer card my-2" } onClick={handleChangeDisplayTeam.bind(this, theTeam.id)}>
                              <div className="card-body">
                                <div className="card-title clearfix">
                                  <a className="primary-link float-end" onClick={handleChangeDisplayTeam.bind(this, theTeam.id)} title="Select Currently Displayed Team" alt="Select Currently Displayed Team">
                                  { theTeam.id === userInfo.displayTeam ?  <i className="bi bi-star-fill"></i> : <i className="bi bi-star"></i> }
                                  </a>

                                  <div><strong>Name</strong>: {theTeam.teamName}</div>
                                  { theTeam.teamTitle ? <div><strong>Title</strong>: {theTeam.teamTitle}</div> : <></> }
                                  <div><strong>ID</strong>: <a target="_blank" rel="noopener noreferrer" href={theTeam.ustaLink}>{ theTeam.id }</a></div>
                                  <div><strong>Season</strong>: {theTeam.season}</div>
                                </div>
                                <div className="card-text">
                                  <div className="small">{theTeam.address}</div>
                                  {theTeam.lineCountSingles ? <div className="small mt-2"><strong>Line Count Singles</strong>: {theTeam.lineCountSingles}</div> : <></> }
                                  {theTeam.lineCountDoubles ? <div className="small mb-2"><strong>Line Count Doubles</strong>: {theTeam.lineCountDoubles}</div> : <></> }
                                </div>
                              </div>
                            </div>
                            </div>
                          ))}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div> 
          </div>
          </div>
      </main>

      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default MyTeams;