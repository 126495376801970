import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebaseAuth";
import logo from "./logo.800x200.png";
import "./PageHeader.css";

function PageHeader(props) {
  const [user] = useAuthState(auth);

  return (
    <header id="header" className="navbar navbar-expand-lg navbar-end navbar-light bg-white">
      <div className="container navbar-topbar">
        <nav className="js-mega-menu navbar-nav-wrap">

          <div id="topbarNavDropdown" className="navbar-nav-wrap-collapse collapse navbar-collapse navbar-topbar-collapse">
          </div>
        </nav>
      </div>

      <div className="container">
          <a className="navbar-brand" href="/" aria-label="Tennis Team Manager">
            <img className="navbar-brand-logo" src={logo} alt="Logo" />
          </a>
          { user &&
          <>
          <div className="float-end">
            <a className="btn btn-primary btn-transition me-2" href="/matches" ><i className="bi bi-calendar2-check me-2"></i><span className="d-md-inline-block d-none">Matches</span></a>
            <a className="btn btn-primary btn-transition me-2" href="/practices" ><i className="bi bi-infinity me-2"></i><span className="d-md-inline-block d-none">Practices</span></a>
            <a className="btn btn-primary btn-transition me-2" href="/teamInfo" ><i className="bi bi-people-fill me-2"></i><span className="d-md-inline-block d-none">Team Info</span></a>
            <a className="btn btn-primary btn-transition me-2" href="/docs" ><i className="bi bi-file-pdf-fill me-2"></i><span className="d-md-inline-block d-none">Docs</span></a>
          </div>
          </>
          }
      </div>
    </header>
  );
}

export default PageHeader;
