import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo, sendMatchAvailabilityEmail } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import { dateStringForTimeZone, timeStringForTimeZone, getTeam, getTeamMembers, getTeamsForSeason, getMatches, setMatchAvailability, updateMatch } from "./firebaseData";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, isWithinInterval } from "date-fns";

import "./Matches.css";

function Matches(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [teams, setTeams] = useState([]);
  const [teamsById, setTeamsById] = useState('');
  const [teamMembers, setTeamMembers] = useState('');
  const [matches, setMatches] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [hasNoTeam, setHasNoTeam] = useState(false);

  const [newMatchDate, setNewMatchDate] = useState(new Date());
  const [newMatchID, setNewMatchID] = useState('')
  const [newMatchTimeDesc, setNewMatchTimeDesc] = useState('')
  const [newMatchHomeTeam, setNewMatchHomeTeam] = useState('')
  const [newMatchAwayTeam, setNewMatchAwayTeam] = useState('')

  const [showSaveMatchAlert, setShowSaveMatchAlert] = useState(false);
  const addMatchModalRef = useRef();

  const navigate = useNavigate();


  async function fetchData(){
    const userDoc = await getUser(user);
    setUserInfo(userDoc);
    setVersionID(await fetchVersionInfo());
    console.log("Props: ", props);

    if(userDoc.displayTeam){
      console.log('Getting team...');
      let theDisplayTeam = await getTeam(userDoc.displayTeam);
      setTeam(theDisplayTeam);

      let teamsForSeason = await getTeamsForSeason(theDisplayTeam.season)
      let teamsById = {};
      teamsForSeason.forEach((theTeam) =>{
        teamsById[theTeam.id] = theTeam;
      });
      setTeams(teamsForSeason);
      setTeamsById(teamsById);
      let theseMembers = await getTeamMembers(userDoc.displayTeam);
      setTeamMembers(theseMembers);
      let theMatches = await getMatches(userDoc.displayTeam);

      let completedMatches = [];
      let unplayedMatches = [];
      theMatches.forEach(theMatch => {
        if(theMatch.won || theMatch.won === false){
          completedMatches.push(theMatch);
        } else {
          unplayedMatches.push(theMatch);
        }
      });
      let orderedMatches = unplayedMatches.concat(completedMatches);
      //setMatches(theMatches);
      setMatches(orderedMatches);
    } else {
      setHasNoTeam(true);
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    console.log('User UID: ', user.uid);

    fetchData();

  }, [user, loading]);

  const handleAvailableClick = (async(match, userInfo, event) => {
    console.log('Available Clicked...');
    console.log(match);
    console.log(userInfo);
    await setMatchAvailability(match, userInfo, true);
    setMatches(await getMatches(userInfo.displayTeam));
  });

  const handleUnavailableClick = (async(match, userInfo, event) => {
    console.log('Available Clicked...');
    console.log(match);
    console.log(userInfo);
    await setMatchAvailability(match, userInfo, false);
    setMatches(await getMatches(userInfo.displayTeam));
  });

  const handleSendMatchAvailabilityClick = (async(match, event) => {
    console.log("Request Match Availability Clicked...");
    var testMode = false;

    if(testMode === false){
      if(!confirm("Send match availability email to everyone on the team?")){
        return(false);
      }
    }
    // Display update message...
    setShowAlert(true);

    sendMatchAvailabilityEmail({match: match, team: team, teamMembers: teamMembers, test: testMode}).then((result) => {
      console.log("Queued up emails requesting Match Availability...");
    });


    let timeout = setTimeout(() => {
      setShowAlert(false);
    }, 5000);

  });

  const handleAddMatch = ((event) => {
    console.log("Add Match!")

    const addMatchModal = addMatchModalRef.current;
    const bsModal = new bootstrap.Modal(addMatchModal);
    bsModal.show();    

  });

  const handleSaveMatch = (async(event) => {
    console.log("Save Match!")

    const newMatchTitle = "Match ID: " + newMatchID + " Match Date: " + dateStringForTimeZone(newMatchDate, "America/Los_Angeles");
    const newMatch = { 
      id: newMatchID,
      date: newMatchDate, 
      available: [], 
      unavailable: [],
      lines: [],
      lineupStatus: "proposed",
      season: team.season,
      title: newMatchTitle,
      times: newMatchTimeDesc,
      home: newMatchHomeTeam,
      visitor: newMatchAwayTeam,
      homeTeam: teamsById[newMatchHomeTeam],
      visitingTeam: teamsById[newMatchAwayTeam],
      localDateString: dateStringForTimeZone(newMatchDate, 'America/Los_Angeles'),
      localTimeString: timeStringForTimeZone(newMatchDate, 'America/Los_Angeles'),
    }
    console.log("New Match: ", newMatch);

    // Display update message...
    setShowSaveMatchAlert(true);

    //Add match to firestore...
    await updateMatch(newMatch);
    fetchData();

    let timeout = setTimeout(() => {
      setShowSaveMatchAlert(false);
      const addMatchModal = addMatchModalRef.current;
      const bsModal = bootstrap.Modal.getInstance(addMatchModal);
      bsModal.hide();   
      }, 2000);

  });

  //console.log('Rendering Matches...');
  let title = "Matches";
  let activeNav = "matches";
  let link = "/matches";
  let emptyMessage = 'No matches found...';
  let currentDate = new Date();
  let sevenDaysFromToday = addDays(currentDate, 7);


  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team} />
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>

                    {hasNoTeam && 
                    <div>
                    <h3 className="mt-10 mb-0 text-center">You are not registered with a team...</h3>
                    <h4 className="mb-10 mt-4 text-center text-muted">Please contact your captain and be sure you registered <br />with the same email address you use for your USTA account.</h4>
                    </div>
                    }

                    { !hasNoTeam && !team && 
                      <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    }
                    { !hasNoTeam && team &&                     
                      <div>
                        <h3>{ team.teamTitle } - Matches</h3>
                        <h6 className="mb-2 text-muted border-top border-bottom py-2">
                          <strong>USTA Team Number</strong>: <a target="_blank" rel="noopener noreferrer" href={team.ustaLink}>{ team.id }</a> [click to open USTA team page]
                        </h6>
                        

                        <div className="my-5">
                        { matches && 
                          <h5 className="clearfix">Matches ({matches.length})
                          { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                          <a className="ms-2 link-primary" name="addMatch" id="addMatch" onClick={handleAddMatch} title="Add Match" alt="Add Match"><i className="bi bi-plus-circle-fill"></i></a>
                          }
                          </h5>
                        }
                        <div className="row-cols-1">
                        { !matches && 
                          <div className="text-center">
                              <h2>Loading...</h2>
                                <Rings
                                height="180"
                                width="180"
                                color="#0000ff"
                                radius="6"
                                wrapperStyle={{display:"block"}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="rings-loading"
                              />
                          </div>
                        }

                        {matches && matches.map(match => (
                          <div className="col" key={match.id}>
                            { match.won === undefined 
                            ?
                          
                              <div className={ isWithinInterval( match.date.toDate(), {start: currentDate, end: sevenDaysFromToday}) ? "card my-2 border border-primary border-5" : "card my-2" }>
                                <div className="card-body">
                                  <div className="card-title text-black clearfix">
                                    <a className="btn btn-primary float-end" href={"/match/" + match.id}><i className="bi bi-info-circle-fill"></i> Details</a>
                                    <strong>Match Date</strong>: { dateStringForTimeZone(match.date.toDate(), 'America/Los_Angeles') } <br />
                                    <strong>Match Times</strong>: { match.times } <br />
                                    <strong>Home Team</strong>: {match.homeTeam.teamName}<br />
                                    <strong>Visiting Team</strong>: {match.visitingTeam.teamName}<br />
                                    <strong>Match Location</strong>: {match.homeTeam.address}
                                  </div>

                                  <div className="card-text">
                                    <div className="clearfix">
                                      <div className="small text-muted">Please click Available or Unavailable</div>
                                      <button type="button" className="btn btn-outline-success me-2 mt-2" onClick={handleAvailableClick.bind(this, match, userInfo)}>
                                        { match.available.includes(userInfo.uid)
                                        ? <span><i className='bi bi-hand-thumbs-up-fill text-success'></i> Available ({match.available.length})</span>
                                        : <span><i className='bi bi-hand-thumbs-up'></i> Available ({match.available.length})</span>
                                        }
                                      </button>

                                      <button type="button" className="btn btn-outline-danger me-2 mt-2" onClick={handleUnavailableClick.bind(this, match, userInfo)}>
                                        { match.unavailable.includes(userInfo.uid)
                                        ? <span><i className="bi bi-hand-thumbs-down-fill text-danger"></i> Unavailable ({match.unavailable.length})</span>
                                        : <span><i className="bi bi-hand-thumbs-down"></i> Unavailable ({match.unavailable.length})</span>
                                        }
                                      </button>
                                      { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                                      <button enabled={showAlert === true ? 'true': 'false'} type="button" className="btn btn-outline-primary mt-2 float-none float-lg-end" onClick={handleSendMatchAvailabilityClick.bind(this, match)}><i className="bi bi-send-fill me-2"></i>Availability Email</button>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>

                            :

                            <div className={(match.won === true) ? "card my-2 card-win" : match.won === false ? "card my-2 card-loss" : "card my-2"}>
                            <div className="card-body">
                              <div className="card-title text-black clearfix">
                                { userInfo.captainsTeam && userInfo.captainsTeam.includes(team.id) &&
                                  <a className="btn btn-primary float-end" href={"/match/" + match.id}><i className="bi bi-info-circle-fill"></i> Details</a>
                                }
                                <h5>Result: {match.won ? "WON" : "LOST"}</h5>
                                <strong>Match Date</strong>: { dateStringForTimeZone(match.date.toDate(), 'America/Los_Angeles') } <br />
                                <strong>Home Team</strong>: {match.homeTeam.teamName}<br />
                                <strong>Visiting Team</strong>: {match.visitingTeam.teamName}
                              </div>

                            </div>
                          </div>

                            }
                          </div>
                        ))}
                        </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </main>

      { team &&                     
      <div className="modal fade" id="addMatch" tabIndex="-1" aria-labelledby="addMatchLabel" aria-hidden="true" ref={addMatchModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title my-2" id="addMatchLabel">Add Match</h2>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body text-black">                  
              <div className="mb-3">
                <label htmlFor="matchDate"  className="form-label me-2 fw-bold">Match Date and Time: </label><br />
                <ReactDatePicker id="matchDate" className="form-control" showIcon showTimeSelect dateFormat="MM/dd/yyyy h:mm aa" selected={newMatchDate} onChange={(date) => setNewMatchDate(date)} />
              </div>

              <div className="mb-3">
                  <label htmlFor="matchID" className="form-label fw-bold">Match ID</label>
                  <input type="text" className="form-control" name="matchID" id="matchID" value={newMatchID} onChange={(e) => setNewMatchID(e.target.value)} />
              </div>
              <div className="mb-3">
                  <label htmlFor="matchTimeDesc" className="form-label fw-bold">Time Description</label>
                  <input type="text" className="form-control" name="matchTimeDesc" id="matchTimeDesc" value={newMatchTimeDesc} onChange={(e) => setNewMatchTimeDesc(e.target.value)} />
              </div>

              <div className="mb-3">
                  <label htmlFor="homeTeam"  className="form-label fw-bold">Home Team: </label>
                  <select className="form-select" aria-label="Home Team"  id="homeTeam" name="homeTeam" value={newMatchHomeTeam} onChange={(e) => setNewMatchHomeTeam(e.target.value)}>
                    <option>Select the home team...</option>
                    {teams && teams.length > 0 && teams.map(theTeam => (
                      <option key={theTeam.id} value={theTeam.id}>{theTeam.teamName}</option>
                    ))}
                  </select>
              </div>

              <div className="mb-3">
                  <label htmlFor="awayTeam"  className="form-label fw-bold">Away Team: </label>
                  <select className="form-select" aria-label="Away Team"  id="awayTeam" name="awayTeam" value={newMatchAwayTeam} onChange={(e) => setNewMatchAwayTeam(e.target.value)}>
                    <option>Select the away team...</option>
                    {teams && teams.length > 0 && teams.map(theTeam => (
                      <option key={theTeam.id} value={theTeam.id}>{theTeam.teamName}</option>
                    ))}
                  </select>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" onClick={handleSaveMatch}>Save Match</button>
            </div>
          </div>
        </div>
      
        { showSaveMatchAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="saveMatchToast" className="toast show border text-black bg-white" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    <h4>Updating... <Rings height="80" width="80" color="#0000ff" radius="6" wrapperStyle={{display:"inline-block"}} wrapperClass="" visible={true} ariaLabel="rings-loading"/></h4>
                </div>
            </div>
          </div>
        )}

      </div>
      }



      { showAlert && (
          <div className="position-fixed top-50 start-50" style={{zIndex: 11}}>
            <div id="liveToast" className="toast show border border-blacktext-black" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <h5 className="me-auto mb-0"><span className="text-success"><i className="bi bi-person-check-fill"></i></span> Success!</h5>
                </div>
                <div className="toast-body text-black">
                  <p>Match Availability Emails Enqueued!</p>                    
                </div>
            </div>
          </div>
      )}


      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Matches;