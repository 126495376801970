import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBarAlt from "./SideBarAlt";
import { registerWithEmailAndPassword, signInWithGoogle } from "./firebaseAuth";
import Typed from 'react-typed';

import "./HomePage.css";

function HomePage(props) {

  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [privacyAccept, setPrivacyAccept] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const register = () => {
    let isValid = true;
    if (!firstName || !lastName) {
      alert("Please enter your full name");
      isValid = false;
      document.getElementById("firstName").focus();
    } else {
      if (!email) {
        alert("Please enter your email address");
        isValid = false;
        document.getElementById("email").focus();
      } else {
        if (!password) {
          alert("Please enter your password");
          isValid = false;
          document.getElementById("password").focus();
        } else {
          if(!privacyAccept){
            alert("Please accept the privacy policy");
            isValid = false;  
            document.getElementById("privacyCheck").focus();
          }
        } 
      }
    }
    if(isValid){
      let user = registerWithEmailAndPassword(firstName, lastName, email, password);
      if(user){
        navigate("/matches");
      }
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    //if (!user) return navigate("/login");

    async function fetchData(){
      try{
        const userDoc = await getUser(user);
        setUserInfo(userDoc);
        setVersionID(await fetchVersionInfo());
        console.log("Props: ", props);
      } catch (err) {
        console.log(err);
      }
      }

    fetchData();
    console.log(window);


  }, [user, loading]);

  let title = "Tennis Team Manager Home Page";
  let activeNav = "home";

  return(
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: ''}]}/>
        <SideBarAlt />

          <div className="container position-relative content-space-t-1 pb-10">
            <div className="row justify-content-lg-between align-items-lg-top">
              <div className="col-md-10 col-lg-5">
                <div className="mb-7">
                  <h1 className="mb-3">
                    Manage your team simply!
                  </h1>
                  <p className="lead">Stop herding cats with emails and text messages!</p>
                </div>

                <div className={ user ? "d-none" : "d-none d-lg-block"}>
                  <img className="d-none img-fluid" src="/assets/svg/illustrations/oc-collaboration.svg" alt="Transaction Minder" />
                  <img className="img-fluid" src="/assets/img/tennis-court.1920.jpg" alt="Transaction Minder" />
                </div>
              </div>

              <div className={ user ? "col-lg-6" : "d-none"}>
                  <img className="d-none img-fluid" src="/assets/svg/illustrations/oc-collaboration.svg" alt="Transaction Minder" />
                  <img className="img-fluid" src="/assets/img/tennis-court.1920.jpg" alt="Transaction Minder" />
                </div>

              <div className={ user ? "d-none" : "col-lg-6"}>

                  <div className="card">
                    <div className="card-header border-bottom text-center">
                      <h4 className="card-header-title">Sign Up <span className="badge bg-warning text-dark rounded-pill ms-1">Player Accounts Always Free!</span></h4>
                    </div>

                    <div className="card-body">
                        <div className="mb-4">
                          <label className="form-label" htmlFor="firstName">First name</label>
                          <input type="text" name="firstName" id="firstName" className="form-control  form-control-lg" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" required />
                          <span className="invalid-feedback">Please enter your first name</span>
                        </div>
                        <div className="mb-4">
                          <label className="form-label" htmlFor="lastName">Last name</label>
                          <input type="text" name="lastName" id="lastName" className="form-control  form-control-lg" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" required />
                          <span className="invalid-feedback">Please enter your last name</span>
                        </div>

                      <div className="mb-4">
                        <label className="form-label" htmlFor="email">Email address</label>
                        <input type="email" name="email" id="email" className="form-control form-control-lg" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail Address" required />
                        <span className="invalid-feedback">Please enter your email address</span>
                      </div>

                      <div className="mb-4">
                        <label className="form-label" htmlFor="password">Password</label>
                        <input type="password" name="password" id="password" className="form-control form-control-lg" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                        <span className="invalid-feedback">Your password must include 8+ characters</span>
                      </div>

                      <div className="form-check mb-4">
                        <input type="checkbox" className="form-check-input" id="privacyCheck" name="privacyCheck" required onChange={(e) => setPrivacyAccept(e.target.checked)} />
                        <label className="form-check-label small" htmlFor="privacyCheck"> I have read and acknowledged the <a href="/privacy">Privacy Policy</a></label>
                        <span className="invalid-feedback">Please accept our Privacy Policy.</span>
                      </div>

                      <div className="mb-3 text-center">
                        <button className="btn btn-dark mb-2" onClick={register}>Register info above</button>
                        <button className="btn btn-primary mb-2 ms-2" onClick={signInWithGoogle}><i className="bi bi-google me-2"></i>Register with Google</button>
                      </div>
                    </div>
                  </div>

              </div>
            </div>
          </div>

      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />

    </>

  )
}

export default HomePage;