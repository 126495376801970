import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import { getTeam, getFileList } from "./firebaseData";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBar from "./SideBar";

// https://mhnpd.github.io/react-loader-spinner/
import { Rings } from "react-loader-spinner";
import "./Docs.css";

function Docs(props) {
  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');
  const [team, setTeam] = useState('');
  const [files, setFiles] = useState([]);
  const [hasNoTeam, setHasNoTeam] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");

    console.log('User UID: ', user.uid);

    async function fetchData(){
      const userDoc = await getUser(user);
      setUserInfo(userDoc);
      setVersionID(await fetchVersionInfo());
      console.log("Props: ", props);

      if(userDoc.displayTeam){
        console.log('Getting team...');
        setTeam(await getTeam(userDoc.displayTeam));
        setFiles(await getFileList(userDoc.displayTeam));
      } else {
        setHasNoTeam(true);
      }
    }
    fetchData();

  }, [user, loading]);

  //console.log('Rendering Matches...');
  let title = "Docs";
  let activeNav = "docs";
  let link = "/docs";
  let emptyMessage = 'No docs found...';

  return (
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
      <main id="content" role="main" className="bg-light">
        <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: link}]}/>

        <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className="row">
            <div className="col-lg-3">
              <SideBar user={user} userInfo={userInfo} versionID={versionID} activeNav={activeNav} team={team}/>
            </div> 
            <div className="col-lg-9">
              <div className="card">
                <div className="card-body">
                  <div>

                    {hasNoTeam && 
                      <div>
                      <h3 className="mt-10 mb-0 text-center">You are not registered with a team...</h3>
                      <h4 className="mb-10 mt-4 text-center text-muted">Please contact your captain and be sure you registered <br />with the same email address you use for your USTA account.</h4>
                      </div>
                    }

                    { !hasNoTeam && (!files || !team) && 
                      <div className="text-center">
                          <h2>Loading...</h2>
                            <Rings
                            height="180"
                            width="180"
                            color="#0000ff"
                            radius="6"
                            wrapperStyle={{display:"block"}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="rings-loading"
                          />
                      </div>
                    }
                    { !hasNoTeam && (files && team) &&                     
                      <div className="card-body">
                        <h3 className="card-title">{ team.teamTitle } - Docs</h3>
                        <h6 className="card-subtitle mb-2 text-muted border-top border-bottom py-2">
                          <strong>USTA Team Number</strong>: <a target="_blank" rel="noopener noreferrer" href={team.ustaLink}>{ team.id }</a> [click to open USTA team page]
                        </h6>
                        

                        <div className="my-5">
                        { files && 
                          <h6 className="clearfix">Docs ({files.length})</h6>
                        }
                        <ol className="small">
                        {files && files.map((file, index) => (
                          <li key={index}>
                                <a href={file.url} target="_blank"><i className="bi bi-file-earmark-pdf-fill"></i></a>
                                <a href={file.url} target="_blank">{ file.name }</a>
                          </li>
                        ))}
                        </ol>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />
    </>
  );
}

export default Docs;