import "./App.css";
import {React} from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { auth, logout } from "./firebaseAuth";
import ScrollToTop from "./ScrollToTop";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import TeamInfo from "./TeamInfo";
import Matches from "./Matches";
import MatchDetail from "./MatchDetail";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import HomePage from "./HomePage";
import Docs from "./Docs";
import Practices from "./Practices";
import Facilities from "./Facilities";
import Seasons from "./Seasons";
import Players from "./Players";
import Teams from "./Teams";
import MyTeams from "./MyTeams";

function RequireAuth({ children }) {
  return auth ? children : <Navigate to="/login" replace />;
}


function App() {
  return (
    <div className="app">
      <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route exact path="/logout" action={logout} element={<Login />} />
            <Route exact path="/teamInfo" element={<RequireAuth><TeamInfo list="teamInfo" status="open"/></RequireAuth>} />
            <Route exact path="/roster" element={<RequireAuth><TeamInfo list="teamInfo" status="open"/></RequireAuth>} />
            <Route exact path="/matches" element={<RequireAuth><Matches list="matches" status="open"/></RequireAuth>} />
            <Route exact path="/practices" element={<RequireAuth><Practices /></RequireAuth>} />
            <Route exact path="/practices/auto/:token" element={<Practices />} />
            <Route path="/match/:matchID" element={<RequireAuth><MatchDetail action="view" /></RequireAuth>} />
            <Route path="/match/:matchID/auto/:token" element={<MatchDetail action="view" />} />
            <Route exact path="/docs" element={<RequireAuth><Docs /></RequireAuth>} />
            <Route exact path="/myTeams" element={<RequireAuth><MyTeams /></RequireAuth>} />
            <Route exact path="/seasons" element={<Seasons />} />
            <Route exact path="/facilities" element={<Facilities />} />
            <Route exact path="/players" element={<Players />} />
            <Route exact path="/teams" element={<Teams />} />

          </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;