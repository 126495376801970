import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, getUser, fetchVersionInfo } from "./firebaseAuth";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import PageTop from "./PageTop";
import SideBarAlt from "./SideBarAlt";

import "./Terms.css";

function Terms(props) {

  const [user, loading, error] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState('');
  const [versionID, setVersionID] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    //if (!user) return navigate("/login");

    async function fetchData(){
      const userDoc = await getUser(user);
      setUserInfo(userDoc);
      setVersionID(await fetchVersionInfo());
      console.log("Props: ", props);
    }

    fetchData();
    //console.log(window);

  }, [user, loading]);

  let title = "Terms and Conditons";
  let activeNav = "terms";

  return(
    <>
      <PageHeader user={user} userInfo={userInfo} versionID={versionID} />
        <main id="content" role="main" className="bg-light">
          <PageTop user={user} userInfo={userInfo} versionID={versionID} breadcrumbs={[{title: title, link: ''}]}/>
          <SideBarAlt />

            <div className="container content-space-1 content-space-t-lg-0 content-space-b-lg-2">
              <h1 className="pt-10">Terms and Conditons</h1>
              
              <p>Effective date: 01 January 2023</p>
              
              <p>Please read these Terms and Conditions ("Terms") carefully before using the TransactionMinder website (https://transactionminder.com) operated by Change Real Estate, LLC (doing business as TransactionMinder) ("us," "we," or "our").</p>
              
              <h2>Acceptance of Terms</h2>
              
              <p>By accessing or using TransactionMinder, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our services.</p>
              
              <h2>Use of Services</h2>
              
              <p>TransactionMinder provides services related to account management and service delivery. You agree to use the services only for lawful purposes and in accordance with these Terms.</p>
              
              <h2>Refunds</h2>
              
              <p>If you are not satisfied with our services, you may request a full refund of the subscription fees paid within six months of your subscription start date.</p>
              
              <h2>Account Suspension or Termination</h2>
              
              <p>We reserve the right to suspend or terminate a user account in the event of misuse or violation of these Terms.</p>
              
              <h2>Intellectual Property</h2>
              
              <p>The content and materials on TransactionMinder, including but not limited to text, graphics, logos, and software, are owned or licensed by us and are protected by intellectual property laws. You may not modify, reproduce, distribute, or create derivative works based on such content and materials without our prior written consent.</p>
              
              <h2>Disclaimer</h2>
              
              <p>TransactionMinder and its services are provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, regarding the operation or availability of the services, or the accuracy, reliability, or completeness of any information provided.</p>
              
              <h2>Limitation of Liability</h2>
              
              <p>In no event shall we be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use of TransactionMinder or the services provided.</p>
              
              <h2>Links to Third-Party Websites</h2>
              
              <p>TransactionMinder may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>
              
              <h2>Modifications to Terms</h2>
              
              <p>We reserve the right to modify or replace these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on TransactionMinder. You are responsible for regularly reviewing the Terms. Continued use of the services after any such changes constitutes your consent to such changes.</p>
              
              <h2>Contact Us</h2>
              
              <p>If you have any questions or concerns about these Terms, please contact us at privacy@transactionminder.com.</p>



      </div>
      </main>
      <PageFooter user={user} userInfo={userInfo} versionID={versionID} />

    </>

  )
}

export default Terms;